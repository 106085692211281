.wrapper {
  display: flex;
  align-items: center;
  .item {
    width: 42px;
    height: 56px;
    background-color: #2f2f35;
    border-radius: 6px;
    font-size: 36px;
    font-weight: 500;
    color: #d3d3d5;
    text-align: center;
  }
  .item + .item {
    margin-left: 5px;
  }
  span {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 36px;
    font-weight: 500;
    color: #d3d3d5;
  }
}
