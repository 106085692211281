@import "../../../../variables";

.mb-15 {
  margin-bottom: 15px;
}

.briefing-modal-text {
  font-size: 16px;
  color: $color-white;
  opacity: 0.8;
}

.briefing-card-text {
  font-size: 15px;
  color: $color-white;
  opacity: 0.9;
}

.sidebar-content {
  padding: 20px 30px;
  transition: 0.2s;
}

.onHover {
  cursor: pointer;
  &:hover .briefing-button {
    transition: 0.2s;
    color: $color-red;
  }
}

.briefing-button {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.briefing-modal {
  width: 94% !important;
  max-width: 740px;
}
