@import "../../variables";
.container {
  background: #0e0f11;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .app-body {
    width: 100%;
    .side-bar {
      position: fixed;
      z-index: 999;
      margin-top: 30px;
      .side-bar__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 12px 14px;
        background-color: $color-card-secondary;
        transform: translateX(0px);
        transition: transform 0.5s ease-in-out;
        .side-bar__inner--item {
          text-transform: uppercase;
          font-size: 15px;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
          .user-avatar {
            cursor: pointer;
          }
        }
      }
      .side-bar--hidden {
        transform: translateX(-60px);
      }
      .arrow-btn {
        position: absolute;
        background: $color-modal;
        width: 14px;
        height: 45px;
        left: 60px;
        top: 20px;
        box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .arrow-icon {
          padding: 0;
          font-size: 8px;
          color: $color-white;
        }
        transition: left 0.5s ease-in-out;
        &--hide-sidebar {
          background-color: $color-red;
          left: 0px;
        }
      }
    }
    .app-content {
      transition: margin-left 0.5s ease-in-out;
      margin-left: 55px;
      &--hide-side-bar {
        margin-left: 0;
      }
    }
  }
}
.ant-dropdown{
  position: fixed !important;
}
