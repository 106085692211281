@import "../../variables";
.tooltip {
  padding: 20px;
  color: $color-white;

  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .link {
    display: flex;
    margin-bottom: 25px;

    .label {
      color: red;
      margin-right: 15px;
    }

    .value {
      font-size: 14px;
    }
  }

  .list {
    margin-bottom: 10px;

    .label {
      font-size: 16px;
      font-weight: 700;
    }

    .item {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        color: #fa4b5c;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.hide {
  display: none !important;
}

:global(.skillTooltipWrapper) {
  max-width: none;
  :global(.ant-tooltip-inner) {
    width: 520px;
    box-shadow: 0px 0px 80px rgba(14, 15, 17, 0.5);
    border-radius: 12px;

    :global(.ant-list-item) {
      color: $color-red;
      font-size: 16px;
      font-weight: 700;
      justify-content: flex-start;
      border-bottom: none;
      padding: 3px 0;
      :global(.ant-typography) {
        margin: 5px;
        svg {
          circle {
            stroke: $color-red;
            fill: $color-red;
          }
        }
      }
    }
    :global(.ant-image) {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}
