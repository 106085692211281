@import "../../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  padding: 10px 40px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .container {
    padding: 10px 20px;
  }
}

.cp {
  cursor: pointer;
}
.box {
  background: $color-card-primary;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;
}

.questionName {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.optionName {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.questionDescription {
  font-size: 18px;
  font-style: italic;
  color: rgba(255, 255, 255, 1);
  opacity: 0.8;
}

.questionOptions {
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  border: 1px solid rgba(48, 48, 54, 1);
  font-weight: 500;
  font-size: 18px;
  line-height: 86px;
  margin: 10px 5px;
}

.questionOptionsSelected {
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  border: 1px solid red;
  font-weight: 500;
  font-size: 18px;
  line-height: 86px;
  margin: 10px 5px;
}

.questionOptionsCheckbox {
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  border: 1px solid rgba(48, 48, 54, 1);
  font-weight: 500;
  font-size: 18px;
  // line-height: 86px;
  margin: 10px 5px;
}

.questionOptionsOrder {
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  border: 2px dotted #303036;
  font-weight: 500;
  font-size: 18px;
  line-height: 86px;
  margin: 10px 5px;
}

.questionOptionsOrderSelected {
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  border: 1px solid rgba(48, 48, 54, 1);
  font-weight: 500;
  font-size: 18px;
  line-height: 86px;
  margin: 10px 5px;
  background-color: #51bd7e;
}

.hide {
  display: none;
}

.questionWrapper {
  padding: 5px 20px;
}

.checkboxWrapper {
  display: flex;
  justify-content: space-between;
}

.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
