@import "../../../../variables";
.path {
  position: relative;
  background: $color-card-primary;
  border-radius: 12px;
  padding: 10px 24px 28px;
  margin-bottom: 20px;
  transition: all 0.2s;
}
.onHover {
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transform: translateY(-8px) scale(1.01);
  }
}
.pathName {
  font-weight: 500;
  font-size: 20px;
  line-height: 56px;
  /* or 430% */
  display: flex;
  align-items: center;
}
.paragraph {
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  opacity: 0.9;
  padding-right: 56px;
}
.info::after {
  content: "";
  height: 72%;
  position: absolute;
  top: 0;
  right: 0;
  border-right: 1px solid #d3d3d533;
}
.verticleLine::after{
  content: none;
}
.listLabel {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.borderBottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d3d533;
}
.paraCourse {
  border-bottom: none !important;
  padding: 0 8%;
  height: 100%;
  align-items: center;
  padding-right: 0;
}
.paraCourseLeft {
  display: flex;
  flex: 5;
  align-items: center;
}
.paraCourseName {
  margin-left: 10px;
  line-height: 28px;
  font-size: 16px;
}
.paraCourseValue {
  flex: 3;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  line-height: 28px;
  font-size: 16px;
}
.progress {
  display: flex;
  justify-content: center;
}
.expand {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 16px;
  cursor: pointer;
  background: transparent;
  border: none;
}
.ant-progress {
  width: 135px !important;
  height: 135px !important;
}

.ant-progress-inner {
  width: 100% !important;
  height: 100% !important;
}

.ant-progress-text {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 86px !important;
}

@media screen and (max-width: 991px) {
  .info {
    padding-right: 0;
    &:after {
      display: none;
    }
  }
  .paraCourse {
    padding: 0;
  }
  .progress {
    justify-content: flex-end;
  }
  .progress,
  .listPara {
    padding: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .progress {
    width: 100%;
  }
  .paragraph {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    -webkit-line-clamp: 3;
    height: 72px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    padding-right: 0px;
    text-align: center;
  }
  .pathName {
    padding-right: 30px;
    line-height: 1.5 !important;
    margin-bottom: 8px;
  }
  .expand {
    top: 14px;
    right: 0;
  }
  .progress {
    justify-content: center;
  }
  .paraCourseValue {
    width: auto !important;
  }

  .more {
    height: auto;
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .borderBottom {
    border-bottom: hidden;
    padding-bottom: 0;
  }
}
