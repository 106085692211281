@import "../../../variables";
.body {
  width: 100%;
  max-width: $screen-max-width;
  margin: 0 auto;
}
.container {
  padding: 0 50px;
  margin: 0 auto;
}
.header {
  // margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  // height: 15vh;
}
.positionheader {
  // margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: 70px;
  z-index: 99;
  background: #0e0f11;
  // height: 15vh;
}
.headerLeft {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  padding-top: 20px;
}
.headingleftTitle{
  width: 80%;
}
.pageSubtitle {
  font-weight: 400;
  font-size: 20px;
  /* or 538% */
  display: flex;
  align-items: center;
  color: $color-white;
  opacity: 0.9;
}
.title {
  display: flex;
  align-items: center;
}
.titleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.titleName {
  font-weight: 500;
  font-size: 22px;
  line-height: 86px;
  /* or 391% */
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: $color-white;
}
.colHomeSecond {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
.colImg {
  justify-content: center;
  // vertical-align: c;
  height: 400px;
  padding: 10px;
  width: 100%;
}
.bgcolor1E1E23 {
  background-color: $color-card-primary;
  min-height: calc(100vh - 312px);
}
.imgPosition {
  object-position: top right;
  object-fit: contain;
  height: 400px;
  width: 100%;
  padding-left: 20%;
}

// MODAL CONFIRM

.modalConfirmWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalConfirmText {
  font-size: 16px;
  font-weight: 400;
  color: $color-white;
  opacity: 0.8;
  text-align: center;
  max-width: 280px;
}
.divider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  background-color: #3a3a43;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 28px;
}
.modalButton {
  display: flex;
  width: 100%;
  gap: 16px;
  max-width: 264px;
  margin-top: 0px;
  margin-bottom: 35px;
  justify-content: space-evenly;
  button:first-child {
    width: 152px;
  }
  button:last-child {
    width: 152px;
  }
}

.backBtn {
  background: transparent;
  // width: 130px;
  border: 1px solid #ed223c;
  color: #ed223c;
  cursor: pointer;
  border-radius: 12px;
  // height: 55px;
  box-sizing: content-box;
  padding: 8px 15px;
}
.nextBtn {
  background: #ed223c;
  // width: 120px;
  border: 1px solid #ed223c;
  color: white;
  cursor: pointer;
  // height: 55px;
  border-radius: 12px;
  box-sizing: content-box;
  padding: 8px 15px;
  .default {
    display: block;
  }
  .defaulthover {
    display: none;
    margin-top: 6px;
    // margin-left: -6px;
    // margin-right: 20px;
    position: relative;
    right: 0px;
    transform: rotate(180deg);
  }
  &:hover {
    .default {
      display: none;
    }
    .defaulthover {
      display: block;
    }
  }
}
:disabled.nextBtn {
  &:hover{
    .defaulthover{
      visibility: hidden;
    }
  }
}


.paragraph {
  font-size: 20px;
  line-height: 28px;
  opacity: 0.9;
  font-family: "Satoshi-Variable" !important;

  // text-align: center;
  p > img {
    object-fit: contain;
    object-position: left;
    width: 100%;
  }
}
.info {
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  max-width: 100%;
  padding-bottom: 20px;

  &:after {
    display: none;
  }
}
.header-logo {
  cursor: pointer;
}
.thankYouDiv {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  // height: 55px;
  // padding: 16px 0px 0px 0px;
  // border-radius: 12px 0px 0px 0px;
  // border: 1px 0px 0px 0px;
  // opacity: 0px;
  // flex-wrap: wrap;
}
.pageNum {
  position: absolute;
  bottom: 15px;
  right: 30px;
  font-size: 21px;
}

.descul > p > ul {
  margin-left: 41px;
}
.iconstyle {
  // padding: 0px 5px 0px 5px;
  // padding-left: 2px;
}

.btnHome {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 29.7px;
  span {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    img {
      vertical-align: top;
    }
  }
}
.btnback {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29.7px;
  gap: 5px;
  span {
    width: 24px;
    height: 10px;
    margin-right: 12px;
    img {
      vertical-align: top;
    }
  }
}
.btnNext {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29.7px;
  gap: 10px;
  // width: 91px;
  p {
    // width: 24px;
    // height: 24px;
    margin-right: 20px;
  }
}
.pages {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25252c;
  gap: 12px;
  width: 70px;
  border-radius: 12px;
  box-sizing: content-box;
  padding: 10px 5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 29.7px;
  text-align: center;
}
.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .background {
    min-height: 120vh;
  }
}

/* media query */

@media screen and (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 576px) {
  .titlePage {
    font-size: 24px !important;
    line-height: 2 !important;
  }
  .buttonHeader {
    width: 100%;
    margin-top: 18px;
  }
  .title {
    margin-top: 8px;
  }
  .titleName {
    font-size: 18px;
    line-height: 3;
  }
  .header {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    height: auto;
    margin-bottom: 20px;
  }
  .colHomeSecond {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .pageNum {
    position: unset;
    margin-bottom: 40px;
    margin-left: 60px;
    text-align: right;
    padding-right: 20px;
  }
  .btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
  }
}
