@import "../../../../variables";

.hovered-icon {
  cursor: pointer;
}
.overlay {
  position: relative;
  display: "text-center";
  svg {
    width: 100%;
  }
}
.domain-controller {
  position: absolute;
  width: 18%;
  height: 30%;
  right: 0;
  top: 35%;
  // background-color: rgba($color: #000000, $alpha: 0.5);
  cursor: pointer;
}
.web-server {
  // position: absolute;
  width: 30px;
  height: 30px;
  right: 17%;
  bottom: 0;
  cursor: pointer;
  // transform: translateX(-50%);
  object-fit: contain;
}
.kali-linux {
  margin: 0px 10px;
  box-sizing: "border-box";
  height: 40px;
  width: 40px;
  object-fit: contain;
  // justify-content: center;
}
// Diagram tooltip
.diagram-tooltip-overlay {
  :global(.ant-tooltip-arrow) {
    :global(.ant-tooltip-arrow-content) {
      background-color: #d9d9d9 !important;
      &::before {
        display: none;
      }
    }
  }
  :global(.ant-tooltip-inner) {
    background-color: #d9d9d9;
    width: 346px;
  }
}
.wrapper {
  background-color: #d9d9d9;
  min-width: 180px;
}
.text {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  font-family: "Satoshi-Variable" !important;
}
.dividerline {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  background-color: #3a3a43;
  height: 1px;
  margin-top: 10px;
}
.text + .text {
  margin-top: 5px;
}
.ip-and-port {
  display: flex;
  align-items: center;
  .text {
    margin-top: 0 !important;
    &:first-child {
      margin-right: 30px;
    }
  }
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.button {
  background-color: #095499;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 128px;
  height: 26px;
  border: none;
  outline: none;
  margin-top: 8px;
  a {
    color: $color-white;
    font-size: 12px;
  }
}
.legendsClase {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.btnLegend {
  position: absolute;
  top: 20px;
  right: 20px;
}
.tooltipwrapper {
  color: #fff;
}
@media screen and (max-width: 576px) {
  .overlay-hover {
    top: 5%;
  }
  .web-server {
    bottom: 4%;
  }
  .diagram-tooltip-overlay {
    :global(.ant-tooltip-inner) {
      width: 250px;
    }
  }
  .btnLegend {
    position: absolute;
    left: 20px;
    right: inherit;
    z-index: 9;
  }
}

@media screen and (max-width: 400px) {
  .btnLegend {
    position: absolute;
    left: 20px;
    right: inherit;
    z-index: 9;
  }
}
