.nodata {
  margin: 0 auto;
  width: 20%;
  height: auto;
  overflow: hidden;
  text-align: center;
  img {
    width: 50%;
    filter: invert(20%) sepia(90%) saturate(4138%) hue-rotate(342deg) brightness(97%) contrast(92%);

  }
  p {
    margin-top: 20px;
    font-size: 22px;
    color: gray !important;
    font-weight: bold;
  }
}
@media screen and (max-width: 767px) {
  .main {
    margin-bottom: 20px;
  }
  .nodata {
    width: 50% !important;
    display: block !important;
    img {
      width: 50%;
      display: inline-block !important;
      filter: invert(20%) sepia(90%) saturate(4138%) hue-rotate(342deg) brightness(97%) contrast(92%);
    }
    p {
      margin-top: 20px;
      font-size: 18px;
      color: gray !important;
    font-weight: bold;
    }
  }
}

