.headerTitle {
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
  margin: 10px 0;
}
@media screen and (max-width: 991px) {
  .headerTitle {
    font-size: 24px;
  }
}
