@import "../../variables";
.background {
  background-image: url("../../assets//imgs//Ellipse2.png");
  background-position: right;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 91vh;
}
@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .background {
    min-height: 120vh;
  }
}
