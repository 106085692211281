.bground {
  background-color: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  min-height: 100vh;

  .miniuese {
    width: 100%;
    max-width: 500px;
  }

  .mixWidth {
    width: 100%;
    max-width: 800px;
  }

  .form_range {
    text-align: center;
    margin-bottom: 30px;

    img {
      margin-bottom: 15px;
    }

    h5 {
      font-size: 20px;
      color: #fff;
      margin-bottom: 12px;
    }

    h2 {
      font-size: 40px;
      color: #fff;
      margin-bottom: 0px;
    }



  }

  .form_box {
    background: #1e1e23;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 30px;
    position: relative;
    width: 100%;

    transition: all .2s;

    p {
      margin-bottom: 6px;
      font-weight: 600;
    }

    input {
      padding: 10px 15px;
      background-color: #363636;
      padding-left: 0px;
      border-radius: 5px;
      border: none;
      width: 100%;

      &:focus-visible {
        outline: none;
      }
    }

    .open_btn {
      text-align: right;
    }
  }

  .newone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    a {
      text-decoration: underline;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 0px;
  border: none;
  background-color: #363636;
}

.ant-input-password-icon.anticon {
  margin-right: 15px;
  color: #fff;

}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: #363636;
}

.google_login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  gap: 12px;
  border-color: #fff;
  color: #212121;

  svg {
    font-size: 24px;
  }

  span {
    font-size: 14px;
  }

  &:focus {
    color: #1e1e23;
  }
}


.qr-code {
  display: flex;

  gap: 27px;

  margin-bottom: 20px;

  canvas {
    border-radius: 10px;
  }

  .qr_box {
    text-align: left;

    h5 {
      font-size: 22px;
      color: #fff;
    }

    ul {
      list-style-type: decimal;
      padding-left: 27px;

      li {
        font-size: 16px;
      }
    }
  }
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  -webkit-flex-grow: 0 !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  justify-content: center !important;
}

.inputIcon {
  margin-left: 12px;
  margin-right: 10px;
  margin-top: 8px;
}
.imgSize{
  width: 300px;
  height: 81px;
  object-fit: contain;
}
.google_unregistered{
  background-color: #212121;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    font-size: 250px;
    color: brown;
  }
  h5{
    font-size: 40px;
    color: #fff;
  }

}
