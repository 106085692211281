@import "../../../../../variables";
.wrapper {
  background-color: $color-modal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  padding: 10px 0;
  p {
    font-size: 16px;
    color: $color-white;
  }
  h3 {
    font-size: 28px;
    font-weight: 500;
    color: $color-white;
    margin-bottom: 0;
  }
}
