.card {
  height: auto;
  margin-bottom: 20px;
  transition: 0.2s;
}

.pathName {
  font-weight: 500;
  font-size: 20px;
}
.info::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-right: 1px solid rgba(211, 211, 213, 0.2);
}
.paragraph {
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  opacity: 0.9;
  padding-right: 20px;
}
.paraCourse {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 4%;
  align-items: center;
}
.paraCourseLeft {
  display: flex;
  flex: 5;
  align-items: center;
}
.paraCourseName {
  margin-left: 10px;
  line-height: 28px;
  font-size: 16px;
}
.paraCourseValue {
  width: 64px;
  font-weight: 300;
  text-align: right;
  line-height: 28px;
  font-size: 16px;
  flex: 3;
}
.progress {
  display: flex;
  justify-content: flex-end;
}
.expand {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 15px;
}

.labelContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  div + div {
    margin-left: 10px;
  }
}

.onHover {
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
    transform: translateY(-8px) scale(1.01);
  }
}

@media screen and (max-width: 991px) {
  .paraCourse {
    padding: 0 20px;
  }
  .para {
    // flex: 0 0 auto;
    max-width: auto;
  }
}

@media screen and (max-width: 767px) {
  .path {
    margin-left: 0;
    // margin-right: 10px;
  }
  .info {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 1px solid rgba(211, 211, 213, 0.2);
    padding-bottom: 20px;
    &:after {
      display: none;
    }
  }
  .pathName {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
  .paragraph {
    text-align: center;
  }
  .paragraph,
  .paraCourseName,
  .paraCourseValue {
    font-size: 14px;
  }
  .paraCourse {
    padding: 10px 0;
    max-width: 400px;
    margin: 0 auto;
  }
  .paraCourseValue {
    width: auto !important;
    flex: 5;
    text-align: right;
  }
  .item {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .progress {
    justify-content: center;
  }
  .labelContainer {
    justify-content: center !important;
  }
}
@media screen and (max-width: 576px) {
  .info {
    border-right: none;
  }
  .item {
    width: 260px;
  }
}
.backgroundClr {
  border-top: 2px solid #51bd7e;
  background: #3b3b46 !important;
}
.topRight {
  position: absolute;
  top: 0px;
  right: 17px;
}
.completedLabel {
  color: #51bd7e;
  font-size: 18px;
  font-weight: 500;
  line-height: 86px;
  // text-align: right;
}
.newLabel {
  color: #ed223c;
  font-size: 18px;
  font-weight: 500;
  line-height: 86px;
  // text-align: center;
}
