@import "../../../../variables";

.launch-text {
  font-size: 18px;
  font-weight: 700;
  color: $color-white;
  opacity: 0.9;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.loading-icon-container {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 39px;
  margin-top: 31px;
  .loading-text {
    color: $color-white;
    opacity: 0.5;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.loading-icon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
