@import "../../../../variables";
.line-wrapper {
  min-height: 160px;
  display: flex;
  align-items: center;
}
.line {
  flex: 1;
  height: 6px;
  background-color: $color-modal;
  border-radius: 3px;
  position: relative;
  margin-right: 7px;
}
.line-point {
  position: absolute;
  top: -7px;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 9px;
    left: 6px;
    top: 22px;
  }
  div {
    cursor: default !important;
    box-shadow: -10px 0px 52px -4px rgba(0, 0, 0, 0.5);
  }
}
.line-point {
  &:hover div {
    z-index: 9;
  }
}
.tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 135px;
  max-width: 200px;
  min-height: 35px;
  background-color: #471f29;
  border-radius: 8px;
  padding: 3px 5px;
  top: 36px;
  transform: translateX(-40%);
  p {
    color: $color-white;
    font-size: 12px;
    opacity: 0.9;
    text-align: center;
  }
}
.start-point {
  left: 0%;
  &::before {
    background-color: $color-green;
  }
  div {
    left: 0;
    transform: translateX(0);
    background-color: transparent;
    box-shadow: none;
    p {
      text-align: left;
    }
  }
}
.start-on-top {
  &::before {
    top: -11px;
  }
  div {
    top: -60px;
  }
}
.end-point {
  left: 100%;
  &::before {
    background-color: $color-green;
    top: -11px;
  }
  div {
    right: 0;
    top: -60px;
    transform: translateX(0);
    background-color: transparent;
    box-shadow: none;
    p {
      text-align: right;
    }
  }
}
.end-on-bottom {
  &::before {
    top: 22px;
  }
  div {
    top: 36px;
  }
}
.red-point {
  &::before {
    background-color: $color-red;
    top: -11px;
  }
  div {
    top: unset;
    bottom: 40px;
  }
}
.blue-point {
  &::before {
    background-color: $color-blue;
    top: 22px;
  }
  div {
    background-color: #203249;
  }
}
