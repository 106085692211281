.ant-pagination-item,
.ant-pagination-item-active{
  border-radius: 50%;
    background: transparent !important;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link{
  border-radius: 50%;
  border-color: rgb(215 215 215 / 25%);
    background: transparent !important;
    color: rgb(215 215 215 / 25%);
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  background: transparent !important;
  border-radius: 50%
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  display: none;
}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #fff;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #fff;
}
