@import "../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  min-height: 100vh;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.wrapper {
  width: 100%;
  padding: 0 40px;
  padding-top: 20px;
}
p {
  margin-bottom: 0;
}
.header {
  display: flex;
  margin-bottom: 10px;
  p:first-child {
    flex: 1;
  }
}
.header-right {
  display: flex;
  align-items: center;
}
.header-right-item {
  display: flex;
  align-items: center;
}
.header-category {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #ffffff80;
}
.header-right-title {
  margin: 0;
  color: $color-white;
  opacity: 0.8;
  margin-left: 15px;
  font-size: 16px;
  flex: 1;
}
.timeline-card-container {
  overflow-x: auto;
  padding-bottom: 14px;
  padding-top: 1px;
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #4a4a50;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: $color-card-primary;
    border-radius: 4px;
  }
}
.timeline-card {
  overflow-x: auto;
  min-width: 1300px;
}
.timeline-title {
  font-size: 22px;
  font-weight: 500;
  color: $color-white;
  margin-bottom: 15px;
}
.missions {
  font-size: 22px;
  font-weight: 500;
  color: $color-white;
  margin-top: 10px;
  margin-bottom: 5px;
}

.team-name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.score-percent {
  display: flex;
  width: 100%;
  height: 100%;
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    &:not(:last-child) {
      border-right: 1px solid $color-modal;
    }
  }
}

@media screen and (max-width: 576px) {
}
@media screen and (max-width: 767px) {
  .header {
    flex-direction: column;
  }
  .header-right {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .header-category {
    border-color: transparent;
    margin-bottom: 10px;
  }
  .score-percent {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .wrapper {
    padding: 0 20px;
  }
}
