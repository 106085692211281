@import "../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  padding: 10px 40px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .container {
    padding: 10px 20px;
  }
}
.box {
  background: $color-card-primary;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;
}

.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}

.colHomeSecond {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.colBtnRight {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  // height: 55px;
  // padding: 16px 0px 0px 0px;
  // border-radius: 12px 0px 0px 0px;
  // border: 1px 0px 0px 0px;
  // opacity: 0px;
  // flex-wrap: wrap;
}

.btnHome {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 29.7px;
  span {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    img {
      vertical-align: top;
    }
  }
}
.btnback {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29.7px;
  gap: 5px;
  span {
    width: 24px;
    height: 10px;
    margin-right: 12px;
    img {
      vertical-align: top;
    }
  }
}
.btnNext {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29.7px;
  gap: 10px;
  // width: 91px;
  p {
    // width: 24px;
    // height: 24px;
    margin-right: 20px;
  }
}

.backBtn {
  background: transparent;
  // width: 130px;
  border: 1px solid #ed223c;
  color: #ed223c;
  cursor: pointer;
  border-radius: 12px;
  // height: 55px;
  box-sizing: content-box;
  padding: 8px 15px;
}
.nextBtn {
  background: #ed223c;
  // width: 120px;
  border: 1px solid #ed223c;
  color: white;
  cursor: pointer;
  // height: 55px;
  border-radius: 12px;
  box-sizing: content-box;
  padding: 8px 15px;
  .default {
    display: block;
  }
  .defaulthover {
    display: none;
    margin-top: 6px;
    // margin-left: -6px;
    // margin-right: 20px;
    position: relative;
    right: 0px;
    transform: rotate(180deg);
  }
  &:hover {
    .default {
      display: none;
    }
    .defaulthover {
      display: block;
    }
  }
}

.thankYouDiv {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paragraph {
  font-size: 20px;
  line-height: 28px;
  opacity: 0.9;
  font-family: "Satoshi-Variable" !important;
  // text-align: center;
  p > img {
    object-fit: contain;
    object-position: left;
    width: 100%;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
