@import "../../../variables";
.container {
  display: flex;
  justify-content: center;
  background: $color-black;
  height: 70px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  max-width: $screen-max-width;
  .hamburger {
    display: none;
    cursor: pointer;
  }
  .header-logo {
    cursor: pointer;
    padding-right: 10px;
  }

  .header_menu {
    display: flex;
    align-items: center;
    .header_menu-title {
      color: red;
    }
  }
  .header_menu-item {
    span {
      text-align: left !important;
    }
  }
}
.header-drawer-extra {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-right: 50px;
  }
  span {
    cursor: pointer;
  }
}
.header_user-menu {
  background-color: $color-card-secondary;
  min-width: 184px;
}
.navigate {
  display: flex;
  flex: 1;
  margin-left: 40px;
}
.nav_button {
  display: flex;
  align-items: center;
  width: 175px;
  height: 70px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 0 10px;
  svg,
  span {
    transition: 0.2s;
  }

  &:hover svg {
    opacity: 0.7;
    stroke: $color-red;
  }
  &:hover span {
    opacity: 0.7;
    color: $color-white;
  }
}
.nav_button + .nav_button {
  margin-left: 20px;
}

.nav_button-title {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  color: #7e7e89;
  text-align: center;
}

.header_user {
  display: flex;
  align-items: center;
  max-width: 220px;
  height: 45px;
  cursor: pointer;
  background: $color-card-secondary;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.1),
    0px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  .user-avatar {
    margin: 6px;
    border-radius: 10px;
    background-color: #2f2f37;
  }
  .user-name {
    font-size: 16px;
    font-weight: 700;
    color: $color-white;
    margin: 0;
    margin-left: 4px;
    margin-right: 4px;
  }
  .user-arrow {
    display: flex;
    margin-left: auto;
    margin-right: 6px;
  }
}
.header_timer-component {
  width: auto;
  min-width: unset !important;
  padding-left: 10px;
  padding-right: 10px;
}
.header_timer {
  margin-right: 10px;
}
.mt-40 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.nav-active {
  border-bottom: 2px solid #ed223c !important;
  span {
    color: white !important;
  }
  svg {
    stroke: $color-red;
  }
}
.visible {
  display: block !important;
}

.navigate-responsive {
  display: block !important;
  height: auto !important;
}

.hide {
  display: none;
}
@media screen and (max-width: 1110px) {
  .header_timer {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    padding: 0 20px;
  }
  .hamburger {
    display: block !important;
    margin-left: auto;
  }
  .header_user {
    max-width: unset;
    width: 100%;
  }
  .header_user-dropdown {
    display: none !important;
  }
  .navigate {
    display: none !important;
  }
  .nav_button {
    margin: 0 !important;
    width: 100%;
    span {
      text-align: left;
      margin-left: 26px;
    }
  }
  .nav_button-title {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
}

:global(.ant-statistic) {
  display: flex;
  align-items: center;
}
:global(.ant-statistic-content-prefix) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bgsLogo {
  padding-right: 20px;
}
@media screen and (max-width: 576px) {
  .bgsLogo {
    padding-right: 10px;
  }
}
.ant-dropdown{
  position: fixed !important;
}
.icon_scoreb{
  color: rgb(126, 126, 137) !important;
  font-size: 32px;
}
