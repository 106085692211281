@import "../../../../variables";

.launch-text {
  font-size: 18px;
  font-weight: 700;
  color: $color-white;
  opacity: 0.9;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.continue-button {
  margin-top: 45px;
  width: 220px;
}

.remaining-mess-container {
  border-bottom: 1px solid #3a3a43;
  padding-bottom: 15px;
}

.remaining-mess {
  font-size: 15px;
  color: $color-white;
  opacity: 0.9;
  margin-bottom: 25px;
}
.remaining-mess-h3 {
  font-size: 18px;
  font-weight: 700;
  color: $color-white;
  opacity: 0.9;
}

.take-the-call-button,
.open-mail--btn {
  margin-bottom: 40px;
  width: 180px;

  span {
    font-size: 16px;
    font-weight: 500;
  }
}
.open-mail--btn {
  margin-left: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-25 {
  margin-top: 25px;
}

.take-call-modal {
  width: 740px !important;
  :global(.ant-modal-content) {
    :global(.ant-modal-body) {
      wave {
        height: 220px !important;
        background-color: black;
        wave {
          border-right: 1px solid green !important;
        }
      }
    }
  }
  :global(.ant-btn-default) {
    display: block !important;
    width: 150px;
    height: 55px;
    border-radius: 10px;
    background-color: #00984d;
    border: none;
    &:hover {
      opacity: 0.8;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      color: $color-white !important;
    }
  }
  :global(.ant-modal-footer) {
    flex-direction: row-reverse;
    gap: 30px;
  }
}

.mail-modal {
  width: 1190px !important;
  .greeting-part {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #ffffff;
    opacity: 0.9;
    margin-bottom: 20px;
  }
  .body-part {
    margin: -15px 0px -25px -25px;
    img {
      width: 50%;
      height: 100%;
    }
    .ic-1 {
      padding: 25px;
    }
    .ic-2 {
      padding: 0px;
    }
  }
  .end-part {
    margin-top: 40px;

    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #ffffff;
    opacity: 0.9;
  }
  :global(.ant-btn-primary) {
    background-color: #00984d !important;
    border: none;
  }
}
