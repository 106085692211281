@import "../../variables";
.body {
  width: 100%;
  max-width: $screen-max-width;
  margin: 0 auto;
}
.container {
  padding: 0 40px;
  margin: 0 auto;
}

.titlePage {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 74px !important;
  /* or 269% */
  display: flex;
  align-items: center;
  flex: 1;
  color: $color-white !important;
  margin-bottom: 0 !important;
}
.header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.headerLeft {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
}
.pageSubtitle {
  font-weight: 400;
  font-size: 16px;
  /* or 538% */
  display: flex;
  align-items: center;
  color: $color-white;
  opacity: 0.9;
}
.buttonHeader {
  width: fit-content;
  border: 1px solid #292930;
  border-radius: 10px;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  padding: 20px;
  /* or 538% */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: underline;
  color: $color-white;
  opacity: 0.8;
  & span {
    text-decoration-line: underline;
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid #292930;
    border-radius: 10px;
    background: transparent;
    color: $color-white;
    & span {
      text-decoration-line: none;
    }
  }
  &:active,
  &::selection {
    background: #212127;
    outline: none;
    box-shadow: none;
  }
}
.title {
  display: flex;
  align-items: center;
}
.titleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.titleName {
  font-weight: 500;
  font-size: 22px;
  line-height: 86px;
  /* or 391% */
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: $color-white;
}
.path {
  background: $color-card-primary;
  border-radius: 12px;
}
.pathName {
  font-weight: 500;
  font-size: 20px;
  line-height: 86px;
  /* or 430% */
  display: flex;
  align-items: center;
  color: $color-white;
}
.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .buttonHeader {
    width: 100%;
    margin-top: 18px;
  }
}

@media screen and (max-width: 576px) {
  .titlePage {
    font-size: 24px !important;
    line-height: 2 !important;
  }
  .buttonHeader {
    width: 100%;
    margin-top: 18px;
  }
  .title {
    margin-top: 8px;
  }
  .titleName {
    font-size: 18px;
    line-height: 3;
  }
}

.exercisesContainer {
  :global(.ant-collapse-content-box) {
    padding: 0;
  }
  :global(.ant-collapse-header) {
    padding: 0 !important;
  }
  :global(.ant-collapse-item) {
    border-bottom: hidden;
  }
  :global(.slick-dots-bottom) {
    bottom: -20px !important;
  }
}
:global(.ant-progress-circle .ant-progress-text .anticon-check) {
  font-size: 2em;
}
