@import "../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  padding: 10px 40px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .container {
    padding: 10px 20px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.path {
  height: 30px;
  margin-top: 34px;
  & > ol > li > span {
    color: #d3d3d5 !important;
    width: 4px;
    height: 10px;
  }
}

.pathOrigin {
  color: #ed223c !important;
}

.pathLocal {
  color: #d3d3d5 !important;
}

.titlePage {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 86px;
  color: $color-white !important;
}

.skillTable {
  td {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.skillName {
  display: flex;
  align-items: center;
  & .skillNameTitle {
    color: $color-white;
    font-weight: 500 !important;
    font-size: 22px !important;
    padding-right: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

.skillDesc {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.DescTableIcon {
  margin: 0 10px;
}

.rate {
  display: flex;
  align-items: center;
}

.box {
  background: $color-card-primary;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;
}

//table
table,
td,
th {
  border: 1px solid #2f2f37;
}

td,
th {
  min-width: 134px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 12px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #2f2f37;
}

td {
  height: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* or 115% */
  text-align: center;
  color: $color-white;
}
.completionRate {
  padding-right: 14px;
}
.titleTable {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: $color-white;
  opacity: 0.9;
  padding: 14px 0;
}

.scroll {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: overlay;
  margin-bottom: 30px;
  box-shadow: 0 0 0 1px #2f2f37;
  border-radius: 12px;
}

.scroll::-webkit-scrollbar-button {
  display: none;
}
.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll::-webkit-scrollbar {
  height: 8px;
  background-color: transparent;
  display: block;
}
.scroll::-webkit-scrollbar-thumb {
  width: 68px;
  border-radius: 10px;
  background-color: #7f7f7f;
}

@media screen and (max-width: 767px) {
  .flex {
    flex-direction: column;
  }
  .skillName {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
  .path {
    margin: 10px 0;
  }
  .titlePage {
    font-size: 24px !important;
    margin-bottom: 0 !important;
  }
  .skillDesc {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .rate {
    justify-content: space-between;
    width: 100%;
  }
  .DescTableIcon {
    width: 100% !important;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .skillName {
    & .skillNameTitle {
      font-size: 18px !important;
      padding-right: 4px;
    }
  }
  .box {
    margin-top: 30px;
  }
}
