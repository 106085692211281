@import "../../variables";
.sidebar-content {
  padding: 20px 30px;
}
.sidebar-content-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.sidebar-content-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: $color-green;
  text-transform: uppercase;
  margin-left: 13px;
}
.sidebar-content-list {
  margin: 0;
  font-family: "Satoshi-Variable" !important;
}
.sidebar-content-item {
  display: flex;
  list-style: none;
  align-items: flex-start;
  margin-top: 10px;
  div {
    margin-top: 2px;
    margin-right: 8px;
    font-family: "Satoshi-Variable" !important;
  }
}
.sidebar-divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid #3a3a43;
  margin: 15px 0;
}
.sidebar-text {
  font-size: 14px;
  color: $color-white;
  opacity: 0.8;
  font-weight: 500;
  font-family: "Satoshi-Variable" !important;
}
.sidebar-link-container {
  margin-bottom: 15px;
}
.sidebar-link {
  display: flex;
  align-items: flex-start;
  color: #45a6ff;
  text-decoration: underline;
  margin-top: -5px;
  word-break: break-all;
  svg {
    margin-top: 4px;
    margin-right: 10px;
  }
}
.msteam-button {
  width: 100%;
  align-items: center;
  min-height: 60px;
  white-space: break-spaces;
  span {
    flex: 1;
    text-align: center;
  }
}
.mb-0 {
  margin-bottom: 0;
}

@media screen and (max-width: 400px) {
  .header-right-timer {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .simulations_timer {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media screen and (min-width: 993px) and (max-width: 1080px) {
  .msteam-button {
    span {
      font-size: 16px !important;
    }
  }
}
