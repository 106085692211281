@import "../../../../variables";
.sidebar-content {
  padding: 20px 30px;
}
.sidebar-content-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.sidebar-content-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: $color-green;
  text-transform: uppercase;
  margin-left: 13px;
}
.sidebar-content-list {
  margin: 0;
}
.sidebar-content-item {
  display: flex;
  list-style: none;
  align-items: flex-start;
  margin-top: 10px;
  div {
    margin-top: 2px;
    margin-right: 8px;
  }
}
.sidebar-divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid #3a3a43;
  margin: 20px 0;
}
.sidebar-text {
  font-size: 14px;
  color: $color-white;
  opacity: 0.8;
  font-weight: 500;
}
.bold {
  font-weight: 700 !important;
}
.sidebar-link-container {
  margin-bottom: 15px;
}
.sidebar-link {
  display: flex;
  align-items: flex-start;
  color: #45a6ff;
  text-decoration: underline;
  margin-top: -5px;
  word-break: break-all;
  svg {
    margin-top: 4px;
    margin-right: 10px;
  }
}

.att-and-ck-card {
  display: flex;
  background-color: #303039;
  justify-content: center;
}

.mt-20 {
  margin-top: 20px;
}
