@import "../../../variables";
.button {
  color: $color-red;
  background-color: transparent;
  border: 1px solid $color-red;
  border-radius: 10px;
  width: 220px;
  height: 55px;
  &:hover {
    color: $color-red;
    background-color: transparent;
    border: 1px solid $color-red;
    opacity: 0.8;
  }
  &:focus {
    border: 1px solid #cf2138;
    background-color: #cf2138;
    color: $color-white;
  }
  &::after {
    display: none;
  }
}
