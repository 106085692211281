@import "../../variables";

.container {
  width: 100%;
  max-width: 1920px;
  padding-bottom: 50px;
  margin: 0 auto;
}

.wrapper {
  width: 100%;
  padding: 20px 40px;

  a {
    color: #45a6ff;
  }

  .simulations-header {
    width: 100%;
    display: flex;
    align-items: baseline;
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .header-title {
    flex: 1;
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    color: $color-white;
  }

  .header-right-container {
    display: flex;
    align-items: center;
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .header-right-timer {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .header-right-title {
    margin: 0;
    color: $color-white;
    opacity: 0.8;
    margin-left: 15px;
    font-size: 16px;
    flex: 1;
  }

  .separate {
    height: 20px;
    border-left: 1px solid #ffffff80;
    margin: 0 20px;
  }

  .main-card {
    padding: 25px;
  }

  .main-content-text {
    color: $color-white;
    opacity: 0.9;
    font-size: 15px;
    margin-bottom: 15px;
    font-family: "Satoshi-Variable" !important;
  }

  .main-content-title {
    font-weight: 700;
    font-size: 16px;
    color: $color-white;
    opacity: 0.9;
  }

  .briefing-card {
    background-color: $color-modal;
  }

  .sidebar-content {
    padding: 20px 30px;
    transition: 0.2s;
  }
  .onHover {
    cursor: pointer;
    &:hover .briefing-button {
      transition: 0.2s;
      color: $color-red;
    }
  }

  .launch-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch-button {
    margin: 30px auto;
  }
}
.briefing-button {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.msteam-button {
  width: 100%;
  align-items: center;
  min-height: 60px;
  white-space: break-spaces;
  span {
    flex: 1;
    text-align: center;
  }
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-23 {
  margin-top: 23px;
}
.mb-0 {
  margin-bottom: 0;
}
.pl-0 {
  padding-left: 0 !important;
}

.align-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.launch-mode {
  height: 100%;
}

.counting-down {
  height: auto;
  border-bottom: 1px solid #3a3a43;
  padding-bottom: 15px;
}

.briefing-modal {
  width: 94% !important;
  max-width: 740px;
}

.timer-container {
  display: flex;
  align-items: center;
}

.simulations_timer {
  margin-left: auto;
  margin-bottom: 4px;
}

.hide {
  display: none !important;
}

// Sidebar

.sidebar-column-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.sidebar-column {
  flex: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
  &:last-child {
    // flex: 1;
  }
}

@media screen and (max-width: 400px) {
  .header-right-timer {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .simulations_timer {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    padding: 20px;
  }

  .simulations-header {
    flex-direction: column;

    .header-title {
      font-size: 24px;
    }

    .separate {
      margin-top: 2px;
      margin-left: 10px;
      margin-right: 5px;
    }

    .header-right-title {
      font-size: 15px !important;
    }
  }

  .header-right-container {
    align-items: flex-start !important;

    div:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

@media screen and (max-width: 576px) {
  .header-right-container {
    flex-direction: column !important;
  }

  .separate {
    display: none;
  }
}

.simulations-header {
  flex-direction: column;
}

@media screen and (min-width: 1111px) {
  .simulations_timer {
    margin-left: auto;
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1110px) {
  .mt--25 {
    margin-top: -25px;
  }
}
.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
