@import "../../../../variables";

.supporting-item-link {
  text-decoration: underline;
  font-size: 15px;
}

.pl-0 {
  padding-left: 0 !important;
}

.briefing-modal-text {
  font-size: 16px;
  color: $color-white;
  opacity: 0.8;
  margin-left: 18px;
  font-family: "Satoshi-Variable" !important;
}

.briefing-card-text {
  font-size: 15px;
  color: $color-white;
  opacity: 0.9;
  margin-left: 18px;
  font-family: "Satoshi-Variable" !important;
}

.supporting-list {
  word-break: break-word;
  padding-left: 25px;
}

.supporting-item {
  &::marker {
    color: #45a6ff;
  }
}

.supporting-item-modal {
  list-style: none;
  padding-left: 0;
}

.supporting-item-link-modal {
  color: #45a6ff !important;
  font-size: 16px;
  opacity: 0.8;
}

.sidebar-content {
  padding: 20px 30px;
  transition: 0.2s;
}

.onHover {
  cursor: pointer;
  &:hover .briefing-button {
    transition: 0.2s;
    color: $color-red;
  }
}

.briefing-button {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.briefing-modal {
  width: 94% !important;
  max-width: 740px;
}
