@import "../../../../variables";
.main {
  :global(.slick-dots-bottom) {
    bottom: -20px !important;
  }
}
.lessonInform {
  text-align: center;
  color: $color-white;
  font-size: 16px;
  opacity: 0.8;
}
.scroll {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: overlay;
}

.scroll::-webkit-scrollbar-button {
  display: none;
}
.scroll::-webkit-scrollbar-track {
  background-color: $color-card-primary;
  border-radius: 10px;
}
.scroll::-webkit-scrollbar {
  height: 8px;
  background-color: transparent;
  display: block;
}
.scroll::-webkit-scrollbar-thumb {
  width: 68px;
  border-radius: 10px;
  background-color: #7f7f7f;
}

.scroll::-webkit-scrollbar-corner {
  color: #7f7f7f;
}
.nodata {
  margin: 0 auto;
  width: 20%;
  height: 25%;
  overflow: hidden;
  text-align: center;
  img {
    width: 50%;
    filter: invert(20%) sepia(90%) saturate(4138%) hue-rotate(342deg)
      brightness(97%) contrast(92%);
  }
  p {
    margin-top: 20px;
    font-size: 22px;
    color: gray !important;
    font-weight: bold;
  }
}
.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 767px) {
  .main {
    margin-bottom: 20px;
  }
  .nodata {
    width: 50% !important;
    img {
      width: 50%;
      display: inline-block !important;
      filter: invert(20%) sepia(90%) saturate(4138%) hue-rotate(342deg)
        brightness(97%) contrast(92%);
    }
    p {
      margin-top: 20px;
      font-size: 18px;
      color: gray !important;
      font-weight: bold;
    }
  }
}

// @media screen and (min-width: 320px) and (max-width: 768px) {
//   .nodata {
//     width: 50%;
//     img {
//       width: 50%;
//       display: inline-block !important;
//       filter: invert(20%) sepia(90%) saturate(4138%) hue-rotate(342deg)
//         brightness(97%) contrast(92%);
//     }
//     p {
//       margin-top: 20px;
//       font-size: 18px;
//       color: gray !important;
//       font-weight: bold;
//     }
//   }
// }
