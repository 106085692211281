.label {
  font-size: 15;
  line-height: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

// @media screen and (max-width: 767px) {
//   .label {
//     width: auto !important;
//     padding: 0 10px;
//   }
// }
