@import "../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  padding: 10px 40px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .container {
    padding: 10px 20px;
  }
}
.box {
  background: $color-card-primary;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;

}
.scroll{
  width: 100%;
  overflow-x: scroll;
}

.scoreHeader{
  text-align: left;

padding: 14px 20px;

}
.scoreBodyCell{
  text-align: left;
  
  padding: 8px 20px;
  border-bottom: 1px solid #292929; 
}
.scoolImage{
  display: flex;
  align-items: center;
  gap: 12px;
}
.pagination{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imgScore{
  width: 40px;
  height: 40px;
}

.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
