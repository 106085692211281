@import "../../../../variables";
.text-area-container {
  background-color: #0e0f11;
  padding: 10px;
}

.text-area {
  background-color: #0e0f11 !important;
  padding: 10px;
  text-align: justify;
  color: $color-white;
  opacity: 0.9;
  font-size: 15px;
  border-radius: 8px;
}

.short-text-area {
  width: 100%;
  max-width: 300px;
  // height: 25px !important;
  // min-height: 25px !important;
}
.btn-close-modal-question {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 8%;
  width: 152px;
}

.labs-question-wrapper {
  :global(.ant-radio-inner) {
    width: 20px;
    height: 20px;
    background-color: #0e0f11;
    border: 1px solid #292930;
  }
  :global(.ant-radio-wrapper) {
    align-items: center;
    :global(span:last-child) {
      padding-top: 3px;
      color: $color-white;
      opacity: 0.8;
      font-size: 14px;
    }
  }
  :global(.ant-radio-checked .ant-radio-inner) {
    background-color: $color-red;
  }
  :global(.ant-radio-inner::after) {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    background-color: white;
  }

  :global(.ant-modal-content) {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    margin-right: 10px;
    margin-left: 10px;
  }
  :global(.form-item) {
    :global(label) {
      font-size: 16px !important;
      opacity: 0.8;
      color: $color-white;
      font-weight: 400 !important;
      height: auto !important;
      &::before {
        display: none !important;
      }
    }
  }
  :global(.radio) {
    flex-direction: row !important;
    :global(.ant-radio) {
      margin-right: 5px;
      span {
        color: red;
      }
    }
  }
}
