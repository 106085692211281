* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi-Variable";
}

html {
  --antd-wave-shadow-color: #292930;
  scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

// font-family: 'Satoshi-Variable';
// font-family: 'Satoshi-VariableItalic';
// font-family: 'Satoshi-Light';
// font-family: 'Satoshi-LightItalic';
// font-family: 'Satoshi-Regular';
// font-family: 'Satoshi-Italic';
// font-family: 'Satoshi-Medium';
// font-family: 'Satoshi-MediumItalic';
// font-family: 'Satoshi-Bold';
// font-family: 'Satoshi-BoldItalic';
// font-family: 'Satoshi-Black';
// font-family: 'Satoshi-BlackItalic';

@font-face {
  font-family: "Satoshi-Variable";
  src: url("./assets/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Variable.woff") format("woff"),
    url("./assets/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: "Satoshi-VariableItalic";
  src: url("./assets/fonts/Satoshi-VariableItalic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-VariableItalic.woff") format("woff"),
    url("./assets/fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Light";
  src: url("./assets/fonts/Satoshi-Light.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Light.woff") format("woff"),
    url("./assets/fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-LightItalic";
  src: url("./assets/fonts/Satoshi-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-LightItalic.woff") format("woff"),
    url("./assets/fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Regular";
  src: url("./assets/fonts/Satoshi-Regular.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Regular.woff") format("woff"),
    url("./assets/fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-Italic";
  src: url("./assets/fonts/Satoshi-Italic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Italic.woff") format("woff"),
    url("./assets/fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url("./assets/fonts/Satoshi-Medium.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Medium.woff") format("woff"),
    url("./assets/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-MediumItalic";
  src: url("./assets/fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-MediumItalic.woff") format("woff"),
    url("./assets/fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Bold";
  src: url("./assets/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Bold.woff") format("woff"),
    url("./assets/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BoldItalic";
  src: url("./assets/fonts/Satoshi-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-BoldItalic.woff") format("woff"),
    url("./assets/fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Black";
  src: url("./assets/fonts/Satoshi-Black.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Black.woff") format("woff"),
    url("./assets/fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BlackItalic";
  src: url("./assets/fonts/Satoshi-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-BlackItalic.woff") format("woff"),
    url("./assets/fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
