@import "../../../variables";
.button {
  color: $color-white;
  background-color: $color-red;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  padding: 24px 20px;
  height: 55px;
  & svg {
    margin-right: 10px;
  }
  &:hover {
    border: none;
    background: #ef4c61;
    color: $color-white;
  }
  &:focus {
    border: none;
    background-color: #cf2138;
    color: $color-white;
  }
  &::after {
    display: none;
  }
}
