@import "../../../variables";
.button {
  display: inline-flex;
  align-items: center;
  color: #7e7e89;
  background-color: transparent;
  border: 1px solid transparent;
  width: 175px;
  height: 55px;
  &:hover {
    background-color: transparent;
    color: $color-white;
    border: 1px solid transparent;
  }
  &:focus {
    background-color: transparent;
    border: 1px solid transparent;
    color: $color-white;
  }
  span {
    flex: 1;
    margin-left: 5px;
  }
  &::after {
    display: none;
  }
}
