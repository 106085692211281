@import "../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  min-height: 100vh;
  padding-bottom: 50px;
  margin: 0 auto;
}

.wrapper {
  width: 100%;
  padding: 0 40px;
  padding-top: 20px;

  a {
    color: #45a6ff;
  }

  .simulations-header {
    width: 100%;
    display: flex;
    align-items: baseline;
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .header-right-team {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: flex-end;
  }

  .label-team {
    display: flex;
    flex: 1;
    gap: 10px;
    // div:nth-child(1) {
    //   width: 160px !important;
    // }
    // div:nth-child(2) {
    //   width: 120px !important;
    // }
  }

  .header-title {
    flex: 1;
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    color: $color-white;
  }

  .header-right-container {
    display: flex;
    align-items: center;
  }

  .header-right {
    display: flex;
    align-items: center;
  }

  .header-right-title {
    margin: 0;
    color: $color-white;
    opacity: 0.8;
    margin-left: 15px;
    font-size: 16px;
    flex: 1;
  }

  .separate {
    height: 20px;
    border-left: 1px solid #ffffff80;
    margin: 0 20px;
  }

  .main-card {
    padding: 25px;
  }

  .main-content-text {
    color: $color-white;
    opacity: 0.9;
    font-size: 15px;
  }

  .main-content-title {
    font-weight: 700;
    font-size: 16px;
    color: $color-white;
    opacity: 0.9;
  }
}

:global(.labs-content-row) {
  min-height: calc(100vh - 247px);
}

.content-text {
  font-size: 15px;
  color: $color-white;
  opacity: 0.9;
  margin-bottom: 20px;
  font-family: "Satoshi-Variable" !important;
}

.simulations-header {
  flex-direction: column;
}

.diagram {
  background-color: $color-modal;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

// PROVISION
.provision-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.launch-text {
  font-size: 18px;
  font-weight: 700;
  color: $color-white;
  opacity: 0.9;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.loading-icon-container {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 39px;
  margin-top: 31px;

  .loading-text {
    color: $color-white;
    opacity: 0.5;
    font-size: 18px;
    margin-bottom: 0;
  }
}

.counting-down {
  height: auto;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.loading-icon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
.btnLegend {
  position: absolute;
  top: 20px;
  right: 20px;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
.loadingIconContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingicon {
  animation: spin 2s linear infinite;
  margin-bottom: 30px;
}
@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

//
.group-button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.group-button-item {
  width: 230px;
  font-size: 16px;
  font-weight: 500;
  color: $color-white;
  margin-top: 10px;
}

.disabled {
  cursor: not-allowed;
  button {
    color: $color-red;
    background-color: transparent;
    border: 1px solid $color-red;
    pointer-events: none;
  }
}

.complete-exercise-btn-tooltip {
  :global(.ant-tooltip-inner) {
    background-color: $color-card-primary;
    opacity: 0.8;
    border-radius: 6px;
  }
  :global(.ant-tooltip-arrow-content) {
    &::before {
      background: $color-card-primary !important;
    }
  }
}

.modal-question {
  width: 100% !important;
  max-width: 780px;
}

// MODAL CONFIRM

.modal-confirm-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-confirm-text {
  font-size: 16px;
  font-weight: 400;
  color: $color-white;
  opacity: 0.8;
  text-align: center;
  max-width: 402px;
}
.modal-confirm-buttons {
  display: flex;
  width: 100%;
  gap: 10px;
  max-width: 402px;
  margin-top: 35px;
  margin-bottom: 35px;
  justify-content: space-evenly;
  button:first-child {
    width: 152px;
  }
  button:last-child {
    width: 152px;
  }
}

.mt-22 {
  margin-top: -22px;
}

@media screen and (max-width: 576px) {
  .header-right-container {
    flex-direction: column !important;
  }

  .separate {
    display: none;
  }

  .group-button {
    flex-direction: column;
  }

  .group-button-item {
    width: 100%;
  }
  .btnLegend {
    position: absolute;
    left: 20px;
    right: inherit;
    z-index: 9;
  }
  .btn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    padding: 0 20px;
    padding-top: 20px;
  }

  .simulations-header {
    flex-direction: column;

    .header-title {
      font-size: 24px;
    }

    .separate {
      margin-top: 2px;
    }

    .header-right-title {
      font-size: 15px !important;
    }
  }

  .header-right-container {
    align-items: flex-start !important;

    div:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .btnLegend {
    position: absolute;
    left: 20px;
    right: inherit;
    z-index: 9;
  }

  // left: 20px;
  // right: inherit;
}

@media screen and (max-width: 1110px) {
  .header-right-team {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }

  .label-team {
    margin-top: 10px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1110px) {
  .mt--25 {
    margin-top: -25px;
  }
}

.hide {
  display: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// MODAL CONFIRM

.modalConfirmWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalConfirmText {
  font-size: 16px;
  font-weight: 400;
  color: $color-white;
  opacity: 0.8;
  text-align: center;
  max-width: 280px;
}
.divider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  background-color: #3a3a43;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 28px;
}
.modalButton {
  display: flex;
  width: 100%;
  gap: 16px;
  max-width: 264px;
  margin-top: 0px;
  margin-bottom: 35px;
  justify-content: space-evenly;
  button:first-child {
    width: 152px;
  }
  button:last-child {
    width: 152px;
  }
}

.backBtn {
  background: transparent;
  // width: 130px;
  border: 1px solid #ed223c;
  color: #ed223c;
  cursor: pointer;
  border-radius: 12px;
  // height: 55px;
  box-sizing: content-box;
  padding: 8px 15px;
}
.nextBtn {
  background: #ed223c;
  // width: 120px;
  border: 1px solid #ed223c;
  color: white;
  cursor: pointer;
  // height: 55px;
  border-radius: 12px;
  box-sizing: content-box;
  padding: 8px 15px;
  .default {
    display: block;
  }
  .defaulthover {
    display: none;
    margin-top: 6px;
    // margin-left: -6px;
    // margin-right: 20px;
    position: relative;
    right: 0px;
    transform: rotate(180deg);
  }
  &:hover {
    .default {
      display: none;
    }
    .defaulthover {
      display: block;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  // height: 55px;
  // padding: 16px 0px 0px 0px;
  // border-radius: 12px 0px 0px 0px;
  // border: 1px 0px 0px 0px;
  // opacity: 0px;
  // flex-wrap: wrap;
}

.marginRight {
  margin-right: 20px;
}

.btnHome {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 29.7px;
  span {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    img {
      vertical-align: top;
    }
  }
}
.btnback {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29.7px;
  gap: 5px;
  span {
    width: 24px;
    height: 10px;
    margin-right: 12px;
    img {
      vertical-align: top;
    }
  }
}
.btnNext {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 29.7px;
  gap: 10px;
  // width: 91px;
  p {
    // width: 24px;
    // height: 24px;
    margin-right: 20px;
  }
}
.pages {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25252c;
  gap: 12px;
  width: 70px;
  border-radius: 12px;
  box-sizing: content-box;
  padding: 10px 5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 29.7px;
  text-align: center;
}
