@import "../../variables";
.card {
  background-color: $color-card-primary;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-left: 10px;
}

.shadow {
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
}
