// colors
$color-white: #fff;
$color-black: #17171b;
$color-red: #ed223c;
$color-green: #51bd7e;
$color-blue: #2787e0;
$color-card-primary: #1e1e23;
$color-card-secondary: #25252c;
$color-modal: #2b2b31;

// size
$screen-max-width: 1920px;
