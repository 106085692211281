@import "../../variables";
.profileWrapper {
  :global(.ant-form-vertical .ant-form-item-label) {
    padding: 0;
    line-height: normal;
    white-space: normal;
  }

  :global(.ant-form-item-label > label) {
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    color: $color-white;
  }

  :global(.ant-form-vertical .ant-form-item-row) {
    margin-right: 20px;
  }

  :global(.ant-input-affix-wrapper) {
    background: #0e0f11;
    border-radius: 8px;
    border-color: #0e0f11;
    height: 50px;
    margin-top: 4px;
  }

  :global(.ant-input-affix-wrapper > input.ant-input) {
    background: #0e0f11;
    border-radius: 10px;
  }

  :global(.ant-btn-primary) {
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    /* or 538% */
    border-radius: 10px;
    padding: 20px 34px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  :global(.anticon) {
    color: #565758;
    padding-left: 4px;
  }
  :global(.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper) {
    background-color: #0e0f11;
  }
  :global(.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper) {
    background-color: #0e0f11;
  }

  :global(input:-webkit-autofill, input:-webkit-autofill:focus) {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  :global(.form-profile-item) {
    label {
      opacity: 0.9;
      &::before {
        display: none !important;
      }
    }
    :global(.ant-input-prefix) {
      margin-right: 10px;
      margin-left: 15px;
    }
    :global(.ant-input-password-icon.anticon) {
      color: $color-red;
    }
  }
  @media screen and (max-width: 767px) {
    :global(.form-profile-item) {
      :global(.ant-form-item-row) {
        margin-right: 0;
      }
    }
  }
}
.container {
  width: 100%;
  max-width: $screen-max-width;
  padding: 10px 40px;
  margin: 0 auto;
}
.cardWrapper {
  padding: 30px;
}
.headerLabelContainer {
  display: flex;
  gap: 6px;
}
.flex {
  display: flex;
  justify-content: space-between;
}

.path {
  height: 30px;
  margin-top: 34px;
  & > ol > li > span {
    color: #d3d3d5 !important;
    width: 4px;
    height: 10px;
  }
}

.pathOrigin {
  color: #ed223c !important;
}

.pathLocal {
  color: #d3d3d5 !important;
}

.titlePage {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 86px;
  color: $color-white !important;
  opacity: 0.9;
}

.avatarHeader {
  display: flex;
  align-items: center;
}

.avatar {
  position: relative;
  width: fit-content;
  border-radius: 10px;
  overflow: hidden;
}

.avtIcon {
  position: absolute;
  top: -2px;
  right: -2px;
  height: 34px;
}

.btnHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & button {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #45a6ff;
    padding-right: 0;
    & span {
      text-decoration-line: underline;
    }
  }
}

.titleForm {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 86px;
  color: $color-white !important;
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.textPage {
  font-weight: 500;
  font-size: 15px;
  line-height: 32px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  & svg {
    margin-right: 14px;
  }
}
.formFooter {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
@media screen and (max-width: 991px) {
  .container {
    padding: 10px 20px;
  }
  .cardWrapper {
    padding: 15px;
  }
  .formFooter {
    justify-content: center;
  }
}
@media screen and (max-width: 374px) {
  .avatarHeader {
    flex-direction: column;
  }
}
.inputIcon{
  margin-left: 12px;
  margin-right: 10px;
  margin-top: 3px;
}
.ant-image-preview-img-wrapper{
  .ant-image-preview-img{
    max-width: 200px !important;
  }
}
.box_image{
  display: flex;
  align-items: center;
  gap: 16px;
  width: max-content;
}
.uploadLogo{
  background-color: transparent !important;
  border: none !important;
  text-decoration: underline;
  color: #45a6ff;
  white-space: pre;
  cursor: pointer;
}
.imgBoxioc{
  width: 100%;
  height: 65px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #0E0F11;
  border: 1px dashed  #565758;
  position: relative;
  max-width: 65px;
}
.img_pre{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.close_Icon{
  position: absolute;
  right: 0;
  top: -3px;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
}
