@import "../../../../../variables";
.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 16px;
    font-weight: 500;
    color: $color-white;
    margin-bottom: 5px;
  }
}

:global(.debrief-progress) {
  :global(.ant-progress-text) {
    :global(.anticon) {
      color: $color-green;
      font-size: 40px !important;
    }
  }
}
