@import "../../../../../variables";
.wrapper {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border: 1px solid #36363f;
  border-radius: 10px;
  p {
    color: $color-white;
    opacity: 0.9;
  }
  .text {
    font-size: 14px;
  }
  .score {
    font-size: 16px;
  }
}
.wrapper + .wrapper {
  margin-top: 5px;
}
