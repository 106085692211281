@import "../../../variables";
.container {
  width: 100%;
  max-width: $screen-max-width;
  padding: 10px 40px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .container {
    padding: 10px 20px;
  }
}
.box {
  background: $color-card-primary;
  box-shadow: 0px -1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-top: 10px;

}
.profileWrapper {
  :global(.ant-form-vertical .ant-form-item-label) {
    padding: 0;
    line-height: normal;
    white-space: normal;
  }
  :global(.ant-form-item-label,){
    padding-bottom: 0px !important;
  }
:global(.ant-form-item){
margin-bottom: 14px;
}
  :global(.ant-form-item-label > label) {
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    color: $color-white;
  }

  :global(.ant-form-vertical .ant-form-item-row) {
    margin-right: 20px;
  }

  :global(.ant-input-affix-wrapper) {
    background: #0e0f11;
    border-radius: 8px;
    border-color: #0e0f11;
    height: 50px;
    margin-top: 4px;
  }
:global(textarea.ant-input){
  background: #0e0f11;
  border-color: #0e0f11;
  padding: 20px;
  border-radius: 8px;
  padding-left: 42px;
    padding-top: 12px;
}
  :global(.ant-input-affix-wrapper > input.ant-input ) {
    background: #0e0f11;
    border-radius: 10px;
  }

  :global(.ant-btn-primary) {
    font-weight: 500;
    font-size: 16px;
    line-height: 86px;
    /* or 538% */
    border-radius: 10px;
    padding: 20px 34px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  :global(.anticon) {
    color: #565758;
    padding-left: 4px;
  }
  :global(.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper) {
    background-color: #0e0f11;
  }
  :global(.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper) {
    background-color: #0e0f11;
  }

  :global(input:-webkit-autofill, input:-webkit-autofill:focus) {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  :global(.form-profile-item) {
    label {
      opacity: 0.9;
      &::before {
        display: none !important;
      }
    }
    :global(.ant-input-prefix) {
      margin-right: 10px;
      margin-left: 15px;
    }
    :global(.ant-input-password-icon.anticon) {
      color: $color-red;
    }
  }
  @media screen and (max-width: 767px) {
    :global(.form-profile-item) {
      :global(.ant-form-item-row) {
        margin-right: 0;
      }
    }
  }
}
.inputIcon{
  margin-left: 12px;
    margin-right: 10px;
    margin-top: 3px;
  filter:
    brightness(0) saturate(100%) invert(60%) sepia(5%) saturate(595%) hue-rotate(201deg) brightness(82%) contrast(87%);

}
.newTextArea{
  position: relative;
}
.textAreaicon{
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 4;
  filter:
  brightness(0) saturate(100%) invert(60%) sepia(5%) saturate(595%) hue-rotate(201deg) brightness(82%) contrast(87%);

}
