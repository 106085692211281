@import "../../../variables";
.button {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  color: $color-white;
  background-color: #303039;
  border: none;
  border-radius: 10px;
  padding: 24px 18px;
  font-weight: 500;
  font-size: 18px;
  span {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 2;
    & > svg {
      margin-right: 10px;
    }
  }
  &:hover {
    border: none;
    background: #232329;
    color: $color-white;
    span {
      opacity: 0.6;
    }
  }
  &:focus {
    border: none;
    background-color: #313138;
    color: $color-white;
  }
  &::after {
    display: none;
  }
}
