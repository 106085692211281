@import "../../../variables";
.button {
  color: $color-white;
  background-color: #1e928b;
  border: none;
  border-radius: 10px;
  width: 220px;
  height: 55px;
  &:hover {
    border: none;
    background: #49a6a0;
    color: $color-white;
  }
  &:focus {
    border: none;
    background-color: #097a73;
    color: $color-white;
  }
  &::after {
    display: none;
  }
}
